<template>
  <v-dialog
    v-model="dialog"
    min-width="500"
    width="80vw"
    :fullscreen="mobileBreakpoints()"
    @click:outside="clearFields()"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on">

        <v-btn
          v-if="!isPdfReport"
          small
          block
          color="#0da344"
          style="color:white"
          @click="viewTableData()"
        >
          {{ $t('Marker.tab') }}
        </v-btn>

        <v-btn
          v-else
          text
        >
          <v-icon>
            mdi-file-document-multiple
          </v-icon>
        </v-btn>
      </span>
    </template>

    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div class="text-right">
        <v-icon
          color="red"
          size="30"
          class="mt-2 mr-2"
          @click="clearFields()"
        >
          mdi-close
        </v-icon>
      </div>
      <v-card-title
        v-if="!isPdfReport"
        class="green--text text--accent-4 mb-5"
      >
        {{ $t('AlertExPost.tables') }}
        <v-spacer />
        <v-menu
          offset-y
          class="text-center"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#4caf50"
              small
              outlined
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('ServiceCard.export') }}
              <v-icon
                class="ma-1"
                color="#4caf50"
              >
                mdi-table
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in exportExtensions"
              :key="item.type"
              :color="item.color"

              @click="exportTable(item.type)"
            >
              <v-list-item-icon class="mr-1 ml-2">
                <v-icon
                  :color="item.color"
                >
                  mdi-file-export
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    :style="`color: ${item.color}`"
                  >{{ item.type }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-title
        v-else
        class="green--text text--accent-4 mb-5"
      >
        {{ $t('AlertExPost.reports') }}
      </v-card-title>

      <v-card-text v-if="!isPdfReport">
        <v-data-table
          id="requests-table"
          :headers="other_services.headers"
          :items="other_services.tableValues"
          :items-per-page="5"
          sort-by="name"
          class="elevation-1"
          mobile-breakpoint="0"
          :footer-props="{
            itemsPerPageAllText: $t('Table.all'),
            itemsPerPageText: $t('Table.rowsPerPage'),
            pageText: `{0}-{1} ${$t('Table.of')} {2}`
          }"
          :no-data-text="$t('Table.noData')"
        />
      </v-card-text>

      <v-card-text v-else>
        <v-row>
          <v-col
            cols="6"
          >
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="existingStartDate"
                  :label="$t('ServiceCard.startDate')"
                  prepend-icon="mdi-calendar"
                  class="mb-n6"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                :max="nowDate"
                :locale="app_user.app_user_data[0].language"
                first-day-of-week="1"
                @input="getDateValues('start', startDate)"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="6"
          >
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290"
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-text-field
                  v-model="existingEndDate"
                  :label="$t('ServiceCard.endDate')"
                  prepend-icon="mdi-calendar"
                  class="mb-n6"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                :picker-date.sync="startDate"
                no-title
                :min="startDate"
                :max="nowDate"
                :locale="app_user.app_user_data[0].language"
                first-day-of-week="1"
                @input="getDateValues('end', endDate)"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedState"
              item-text="name"
              :items="states"
              :label="$t('AlertExPost.state')"
              item-color="success"
              return-object
              solo
            />
          </v-col>
          <v-col>
            <v-select
              v-model="selectedRegion"
              :items="regions"
              item-text="name"
              :label="$t('AlertExPost.region')"
              item-color="success"
              return-object
              solo
            />
          </v-col>
          <v-col>
            <v-select
              v-model="selectedAdversity"
              :items="other_services.adversity"
              item-text="nome"
              :label="$t('AlertExPost.adversity')"
              item-color="success"
              return-object
              solo
            />
          </v-col>
        </v-row>
        <v-btn
          color="#0da344"
          class="mb-4"
          small
          outlined
          :disabled="checkFields"
          @click="searchPdf()"
        >
          {{ $t('AlertExPost.search') }}
        </v-btn>

        <div v-if="isSearch">
          <v-alert
            v-if="other_services.noPdfFound"
            dense
            type="warning"
            icon="mdi-alert-outline"
            class="mt-4"
            transition="scale-transition"
          >
            {{ $t('Table.noData') }}
          </v-alert>
          <v-simple-table
            v-else
            height="300px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Data
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="pdf in other_services.pdfList"
                  id="requests-table"
                  :key="pdf.id"
                  @click="downloadPdf([pdf.nome_file, pdf.label])"
                >
                  <td>
                    <v-icon>
                      mdi-file-document
                    </v-icon>
                    {{ pdf.label }}
                  </td>
                  <td>{{ pdf.data.split("T")[0] }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>

      <!-- <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="green"
          @click="dialog = false"
        >
          Chiudi
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getFormattedCurrentDate, getFormattedDate } from '@/utils.js';
import { logoRadarmeteo } from '@/base64img.js';
import { json2excel } from 'js2excel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  components: {
  },
  props: {
    isPdfReport: {
      type: Boolean,
      default: false
    },
    existingStartDate: {
      type: String,
      default: ''
    },
    existingEndDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      startDate: this.existingStartDate,
      endDate: this.existingEndtDate,
      selectedRegion: null,
      selectedAdversity: null,
      startDateMenu: false,
      endDateMenu: false,
      isSearch: false,
      overlay: false,
      dialog: false,
      exportExtensions: [
        { type: 'csv', color: 'green' },
        { type: 'pdf', color: 'red' }
      ],
      selectedState: { name: 'Italia', stato_code: 'it' },
      states: [{ name: 'Italia', stato_code: 'it' }],
      regions: [
        { name: 'Abruzzo', regione_code: '13' },
        { name: 'Basilicata', regione_code: '17' },
        { name: 'Calabria', regione_code: '18' },
        { name: 'Campania', regione_code: '15' },
        { name: 'Emilia-Romagna', regione_code: '08' },
        { name: 'Friuli-Venezia Giulia', regione_code: '06' },
        { name: 'Lazio', regione_code: '12' },
        { name: 'Liguria', regione_code: '07' },
        { name: 'Lombardia', regione_code: '03' },
        { name: 'Marche', regione_code: '11' },
        { name: 'Molise', regione_code: '14' },
        { name: 'Piemonte', regione_code: '01' },
        { name: 'Puglia', regione_code: '16' },
        { name: 'Sardegna', regione_code: '20' },
        { name: 'Sicilia', regione_code: '19' },
        { name: 'Toscana', regione_code: '09' },
        { name: 'Trentino-Alto Adige', regione_code: '04' },
        { name: 'Umbria', regione_code: '10' },
        { name: 'Valle d\'Aosta', regione_code: '02' },
        { name: 'Veneto', regione_code: '05' }
      ]
    };
  },
  computed: {
    ...mapState(['other_services', 'app_user']),
    checkFields() {
      return !((this.selectedRegion && this.selectedAdversity && this.startDate && this.endDate));
    }
  },
  watch: {
    existingStartDate: function() {
      this.startDate = this.existingStartDate;
    },
    existingEndDate: function() {
      this.endDate = this.existingEndDate;
    }
  },
  created() {
  },
  methods: {
    ...mapActions(['getReportList', 'downloadPdf', 'viewTable', 'storeToastMessage']),
    mobileBreakpoints() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        default:
          break;
      }
    },
    clearFields() {
      this.isSearch = false;
      this.other_services.noPdfFound = false;
      // this.startDate = null;
      // this.endDate = null;
      this.selectedRegion;
      this.selectedState;
      this.selectedAdversity;
      this.other_services.noPdfFound = false;
      this.dialog = false;
    },
    getDateValues (model, value) {
      switch (model) {
        case 'start':
          this.startDateMenu = false;
          break;
        case 'end':
          this.endDateMenu = false;
          break;
        default:
          break;
      }

      this.$emit('dateValues', model, value);
    },
    exportTable(type) {
      const reportName = (type === 'csv') ?
        'dati_misure_' + getFormattedCurrentDate(new Date()).replace(' ', '_').replace(':', '-') :
        'Report Alert ex-post® - ' + getFormattedCurrentDate(new Date()).replace(' ', ' ore ');

      if (type === 'pdf') {
        require('jspdf-autotable');
        const header = [];
        this.other_services.headers.forEach(item => header.push({ header: item.text, dataKey: item.value }));
        const data = [...this.other_services.tableValues];
        const doc = new jsPDF('l', 'mm', [297, 210], true);
        const totalPagesExp = '{total_pages_count_string}';

        const pageContent = function (data) {
          // HEADER
          doc.setFontSize(20);
          doc.setTextColor(40);

          if (logoRadarmeteo) {
            doc.addImage(logoRadarmeteo, 'JPEG', data.settings.margin.left, 5, 10, 10);
          }

          doc.text(reportName, data.settings.margin.left + 13, 13);

          // FOOTER
          let str = data.pageCount;

          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' di ' + totalPagesExp;
          }

          str += '          Radarmeteo s.r.l. Via IV Novembre, 117 - 35020, Due Carrare (PD) - Italia - Tel: (+39) 049 9125902 - Email: assistenza@radarmeteo.com - Web: www.radarmeteo.com';

          doc.setFontSize(6);
          doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
        };

        doc.autoTable({
          didDrawPage: pageContent,
          columns: header,
          body: data,
          margin: { top: 20, horizontal: 7, left: 20, right: 20 },
          headStyles: { fillColor: [12, 163, 68] },
          bodyStyles: { valign: 'top' },
          styles: {
            overflow: 'linebreak',
            cellWidth: '150',
            fontSize: 6
          },
          columnStyles: { text: { cellWidth: 'auto' } }
        });

        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }

        doc.save(reportName + '.pdf');
      } else {
        const data = [];

        this.other_services.tableValues.forEach(list => {
          const item = {};
          this.other_services.headers.forEach(header => {
            item[header.text] = list[header.value];
          });
          data.push(item);
        });

        try {
          json2excel({
            data,
            name: reportName,
            formateDate: 'yyyy/mm/dd'
          });
        } catch (e) {
          this.storeToastMessage({
            text: `CSV Export failed. ${e}`,
            type: 'danger'
          });
        }
      }
    },
    async searchPdf() {
      this.isSearch = true;
      await this.getReportList({
        state: this.selectedState.stato_code,
        region: this.selectedRegion.regione_code,
        adversity: this.selectedAdversity,
        startDate: this.startDate,
        endDate: this.endDate
      });
      this.$gtag.event('Dati richiesta alert ex-post documentale', {
        event_category: 'ALERT EX-POST',
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.selectedState.name} - ${this.selectedRegion.name} - ${this.selectedAdversity.nome} - ${this.startDate} to ${this.endDate}`
      });
    },
    async viewTableData() {
      this.overlay = true;
      await this.viewTable();
      this.overlay = false;
    }
  }
};
</script>

<style scoped>
tr:hover {
  cursor: pointer;
}
#requests-table {
  white-space: nowrap !important;
}
</style>
